import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import FormItems from './FormItems/FormItems'

const ContactForm = ({ theme }) => {
  const query = useStaticQuery(graphql`
    {
      data: markdownRemark(fileAbsolutePath: { regex: "/sections/forms/" }) {
        frontmatter {
          slug_section
          title
          subtitle
          form_options {
            title
            name
            description
          }
          form_input {
            name
            email
            phone
            message
          }
          form_agreements {
            text
          }
          form_button {
            send
            fill
          }
          form_success {
            title
            text
          }
          form_failure {
            text
            title
          }
          form_config {
            messagetype
            toemail
            fromname
          }
        }
      }
    }
  `)

  return <FormItems data={query.data.frontmatter} theme={theme} />
}

export default ContactForm
