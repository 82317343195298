import React, { useContext, useEffect } from 'react'
import { graphql } from 'gatsby'
import { globals, s, colors, alpha, sHtml } from '../clever-ui-kit/style'
import { T } from '../clever-ui-kit/typo'
import { BlogMinis } from '../clever-ui-kit/minis'
// import '../clever-ui-kit/style/unused/styleOLD.css'

// CONTEXT
import { StateContext, DispatchContext, initialState } from '../context'

// COMPONENTS CONST
import SEO from '../components/seo'
import { IntroBlog } from '../clever-ui-kit/intros'
import ContactForm from '../clever-ui-kit/forms/ContactForm'

export default function BlogTemplate({ data }) {
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)
  // const language = state?.language ? state.language : initialState.language
  const theme = state?.theme ? state.theme : initialState.theme

  const author = data?.author?.frontmatter
  const blog = data.thisBlog?.frontmatter
  const blogHtml = data.thisBlog?.html
  // console.log(data)
  /*eslint-disable */
  useEffect(() => {
    dispatch({ type: 'SET_MENU', payload: false })
  }, [])

  useEffect(() => {
    if (blog.language)
      dispatch({ type: 'SET_LANGUAGE', payload: blog.language })
  }, [])
  /*eslint-enable */

  return (
    <>
      <SEO
        title={blog.seo_title || blog.title}
        description={blog.seo_desc || blog.description}
        image={blog.image?.publicURL}
        date={blog.date}
        slug={'/' + blog.blog_slug}
        isBlog
      />
      <IntroBlog
        title={blog.title}
        subtitle={blog.description}
        image={blog.image}
        author={author}
        date={blog.date}
        theme={theme}
        language={blog.language}
      />
      <div css={sTitle}>
        <T
          d={64}
          t={48}
          m={32}
          mb={0.5}
          bold
          variant="h1"
          extraCss={sTitle.headline}>
          {blog.title}
        </T>
        <T d={20} t={18} m={14} o={1} normal>
          {blog.description}
        </T>
      </div>
      <article
        css={[sHtml, sBlog, sTheme(theme), serviceWidth]}
        dangerouslySetInnerHTML={{ __html: blogHtml }}
      />
      <div css={{ marginBottom: '2rem' }}>
        <BlogMinis
          data={{
            language: blog.language,
            slug_section: 'pozostale-blogi',
            title: 'RDB News',
          }}
          removeMarginTop
          theme={theme}
        />
      </div>
      <div css={{ marginBottom: '4rem' }}>
        <ContactForm theme={theme} />
      </div>
    </>
  )
}

const serviceWidth = {
  maxWidth: '1000px',
}

export const sTheme = (theme) => ({
  backgroundColor: colors[theme].container.concat(alpha[80]),
  [s.md]: {
    p: {
      color: colors[theme].text.concat(alpha[80]),
    },
  },
})

export const sBlog = {
  [s.sm_down]: {
    boxShadow: globals.shadows.sections.mobile,
    borderRadius: globals.roundness.sections.mobile,
    marginBottom: globals.spacing.inside.mobile,
  },
  [s.md]: {
    boxShadow: globals.shadows.sections.desktop,
    borderRadius: globals.roundness.sections.desktop,
    marginBottom: globals.spacing.inside.desktop,
  },
}

export const sTitle = {
  margin: '0 auto',
  width: '100%',
  maxWidth: globals.maxWidth,
  [s.lg_1280]: {
    width: 'calc(100% - 4rem)',
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
  marginTop: '6rem',
  marginBottom: '6rem',
  textAlign: 'center',
  [s.sm_down]: {
    width: 'calc(100% - 4rem)',
    padding: '0 2rem',
    marginBottom: '4rem',
  },
  headline: {
    letterSpacing: '-0.05em',
  },
}

export const query = graphql`
  query($title: String!, $author: String) {
    thisBlog: markdownRemark(
      fileAbsolutePath: { regex: "/blog/" }
      frontmatter: { title: { eq: $title } }
    ) {
      html
      frontmatter {
        title
        blog_slug
        name
        url
        language
        author
        description
        date
        seo_title
        seo_desc
        image {
          name
          publicURL
          childImageSharp {
            fluid(
              maxWidth: 1920
              quality: 100
              toFormat: JPG
              jpegProgressive: true
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    author: markdownRemark(
      fileAbsolutePath: { regex: "/authors/" }
      frontmatter: { title: { eq: $author } }
    ) {
      frontmatter {
        title
        subtitle
        description
        image {
          name
          childImageSharp {
            fluid(
              maxWidth: 64
              quality: 100
              toFormat: JPG
              jpegProgressive: true
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    # sections: allMarkdownRemark(
    #   filter: {
    #     fileAbsolutePath: { regex: "/sections/" }
    #     frontmatter: { name: { in: $sections }, language: { eq: $language } }
    #   }
    # ) {
    #   nodes {

    #   }
    # }
    # otherComponents:
  }
`
