import React from 'react'
import { T } from '../../typo'
import { s, colors, alpha, q } from '../../style'
import { Grid } from '@material-ui/core'

const FormOptions = ({ data, theme, selectedOptions, setSelectedOptions }) => {
  const onClickHandler = (option) => {
    if (selectedOptions.includes(option.name))
      return setSelectedOptions(
        selectedOptions.filter((e) => e !== option.name)
      )
    else return setSelectedOptions(selectedOptions.concat(option.name))
  }

  return (
    <Grid container>
      {data.map((option, id) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={4}
          key={id}
          css={{
            padding: '1rem',
            [s.sm_down]: { padding: '0 0 1.5rem' },
          }}>
          <div
            css={[
              sOption(theme),
              { height: '100%' },
              selectedOptions.includes(option.name) && sOption(theme).active,
            ]}
            onClick={() => onClickHandler(option)}
            onKeyPress={() => onClickHandler(option)}
            role="button"
            tabIndex={0}>
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                paddingBottom: '1rem',
              }}>
              <div className="form_option_check">
                <div className="form_option_check_inside" />
              </div>
              <T
                m={16}
                t={18}
                d={20}
                bold
                condensed
                variant="h3"
                className="form_option_title">
                {option.title}
              </T>
            </div>
            <div className="form_option_description">{option.description}</div>
          </div>
        </Grid>
      ))}
    </Grid>
  )
}

const sOption = (theme) => ({
  transition: 'box-shadow 0.15s ease-out, background-color 0.25s ease-out',
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
  boxShadow: `inset 0px 0px 0px 0px ${colors[theme].main}`,
  [s.sm_down]: { padding: '1.5rem 1.5rem 2rem' },
  [s.md]: { padding: '2rem 2rem 2rem' },
  '.form_option_title': {
    opacity: 0.6,
  },
  '.form_option_description': {
    paddingRight: '2rem',
    opacity: 0.4,
    [s.md]: {
      fontSize: 13,
    },
  },
  '.form_option_description, .form_option_title': {
    transition: 'color 0.25s ease-out, opacity 0.25s ease-out',
  },
  '.form_option_check': {
    transition: 'box-shadow 0.25s ease-out, opacity 0.25s ease-out',
    ...q.center,
    width: 20,
    height: 20,
    boxShadow: `0px 0px 0px 2px ${colors[theme].text}`,
    opacity: 0.3,
    marginRight: '1rem',
    borderRadius: 10,
  },
  '.form_option_check_inside': {
    transition: 'transform 0.25s ease-in-out',
    width: 14,
    height: 14,
    borderRadius: 8,
    backgroundColor: colors[theme].main,
    transform: 'scale(0)',
  },
  '&:hover': {
    [s.hover]: {
      backgroundColor: colors[theme].text.concat(alpha[8]),
    },
  },

  active: {
    boxShadow: `inset 0px 0px 0px 4px ${colors[theme].main.concat(alpha[60])}`,
    '.form_option_title': {
      opacity: 1,
      color: colors[theme].main,
    },
    '.form_option_description': {
      opacity: theme === 'dark' ? 1 : 0.6,
      color: colors[theme].main,
    },
    '.form_option_check': {
      boxShadow: `0px 0px 0px 2px ${colors[theme].main}`,
      opacity: 1,
    },
    '.form_option_check_inside': {
      transform: 'scale(1)',
    },
    '&:hover': {
      [s.hover]: {
        backgroundColor: colors[theme].main.concat(alpha[8]),
      },
    },
  },
})

export default FormOptions
