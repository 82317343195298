import React, { useState, useEffect, useCallback, useRef } from 'react'
import { T } from '../../typo'
import { s, globals, colors } from '../../style'
import { WrapperSection } from '../../wrappers'
import FormOptions from './FormOptions'
import FormInputs from './FormInputs'
import FormAgreements from './FormAgreements'
import { ButtonReactive } from '../../buttons'
import FormBackdrop from './FormBackdrop'
import { useOnScreen } from '../../hooks'
import { validateEmail } from '../../hooks/utils'
import axios from 'axios'
// import CircularProgress from '@material-ui/core/CircularProgress'

const FormItems = ({ theme, data, ...props }) => {
  const {
    // language,
    slug_section,
    title,
    subtitle,
    form_options,
    form_input,
    form_agreements,
    form_button,
    form_success,
    form_failure,
    form_config,
  } = data

  const ref = useRef(null)
  const onScreen = useOnScreen(ref)

  const resetObjectProps = (obj) =>
    Object.keys(obj).forEach((key) => (obj[key] = ''))

  const initialFormData = { ...form_input }
  const initialFormAgreements = form_agreements.map((e) => false)
  resetObjectProps(initialFormData)

  const [selectedOptions, setSelectedOptions] = useState([])
  const [formData, setFormData] = useState(initialFormData)
  const [formAgreements, setFormAgreements] = useState(initialFormAgreements)
  const [mailState, setMailState] = useState('unfilled')
  const [success, setSuccess] = useState(null)
  const [openBackdrop, setOpenBackdrop] = useState(false)

  // console.log(formData)

  const formFilled =
    Object.values(formData).filter((value) => value).length ===
    Object.keys(formData).length

  const agreementFilled =
    formAgreements.filter((value) => value).length === formAgreements.length

  const validate = useCallback(() => {
    return formFilled && agreementFilled && selectedOptions.length > 0
  }, [formFilled, agreementFilled, selectedOptions])

  const sendMail = async () => {
    if (validate()) {
      setSuccess(null)
      setOpenBackdrop(true)
      setMailState('loading')
      axios({
        method: 'POST',
        url:
        process.env.NODE_ENV === 'development'
        ? 'http://localhost:4000/sendmail'
        : 'https://cleverserver.appclever.pl/sendmail',
        data: {
          ...formData,
          selectedOptions,
          toEmail: 'serverSide',
          // cc: [{ email: 'ad@rdbgroup.pl' }],
          template: 'ReklamaKontakt',
        },
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })
        .then((res) => {
          if (res.status === 200) {
            setOpenBackdrop(true)
            setSuccess(true)
            setFormData(initialFormData)
            setFormAgreements(initialFormAgreements)
            setSelectedOptions([])
            // console.log('TRUE')
            // console.log(res)
          } else {
            setOpenBackdrop(true)
            setSuccess(false)
            // console.log('FALSE')
            // console.log(res)
          }
        })
        .catch((error) => {
          setOpenBackdrop(true)
          setSuccess(false)
          // console.log('ERROR')
          // console.log(error)
        })
    }
  }

  useEffect(() => {
    if (validate()) setMailState('filled')
    else setMailState('unfilled')
  }, [validate, setMailState])

  return (
    <WrapperSection id={slug_section}>
      <T
        d={32}
        m={32}
        o={0.16}
        bold
        condensed
        upper
        extraCss={sHeadline}
        onScreen={onScreen}>
        {title}
      </T>
      <div
        css={[
          sContainer,
          sTheme(theme),
          onScreen === false && {
            opacity: 0 /* contentVisibility: 'hidden' */,
          },
        ]}
        ref={ref}>
        <T d={32} m={24} condensed bold extraCss={sText}>
          {subtitle}
        </T>
        <FormOptions
          theme={theme}
          data={form_options}
          setSelectedOptions={setSelectedOptions}
          selectedOptions={selectedOptions}
        />
        <FormInputs
          theme={theme}
          data={form_input}
          formData={formData}
          setFormData={setFormData}
        />
        <FormAgreements
          theme={theme}
          data={form_agreements}
          formAgreements={formAgreements}
          setFormAgreements={setFormAgreements}
        />
        <ButtonReactive
          onClickFunction={sendMail}
          active={mailState === 'filled'}
          theme={theme}
          center>
          {mailState === 'unfilled'
            ? form_button.fill
            : mailState === 'filled'
            ? form_button.send
            : form_button.send}
        </ButtonReactive>
        <FormBackdrop
          success={success}
          theme={theme}
          open={openBackdrop}
          setOpen={setOpenBackdrop}
          dataSuccess={form_success}
          dataFailure={form_failure}
        />
      </div>
    </WrapperSection>
  )
}

const sHeadline = {
  [s.sm_down]: {
    width: '100%',
    textAlign: 'center',
    marginBottom: '2rem',
  },
  [s.md]: { padding: '0 2rem', marginBottom: '4rem' },
}

const sContainer = {
  transition: 'opacity 0.5s ease-out',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  [s.sm_down]: {
    boxShadow: globals.shadows.sections.mobile,
    marginLeft: globals.spacing.outside.mobile,
    marginRight: globals.spacing.outside.mobile,
    borderRadius: globals.roundness.sections.mobile,
    padding: globals.spacing.inside.mobile,
  },
  [s.xs]: {
    margin: 0,
  },
  [s.md]: {
    padding: globals.spacing.inside.desktop,
    paddingBottom: globals.spacing.inside.desktop,
    boxShadow: globals.shadows.sections.desktop,
    borderRadius: globals.roundness.sections.desktop,
    margin: '0 auto',
  },
}

const sTheme = (theme) => ({
  backgroundColor: colors[theme].container,
})

const sText = {
  [s.sm_down]: { marginBottom: globals.spacing.inside.mobile },
  [s.md]: { marginBottom: globals.spacing.inside.desktop / 2 },
}

export default FormItems
